<template>
    
    <div class='date-picker'>
        <v-menu offset-y right content-class="date-picker-list" attach>
            <template v-slot:activator="{ on, attrs }">
                <!-- <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                >
                Dropdown
                </v-btn> -->
            <div  v-bind="attrs"
                    v-on="on">
                 <v-row 
                no-gutters
                >
                    <v-col cols='3'>
                        <div class='timeframe text-center'>{{selectedRange}}</div>
                    </v-col>
                    <v-col cols='8'>
                        <div class='text-center'>
                            <span class='first-date'>{{dates.startDate}}</span>
                            <span class='date-arrow'><v-icon>mdi-arrow-right</v-icon></span>
                            <span class='second-date'>{{dates.endDate}}</span>
                        </div>
                    </v-col>
                    <v-col cols='1'
                   
                    :class='{hide:!viewRangePicker}'
                    >
                        <div class='text-center'>
                            <span class='date-dropdown'><v-icon>mdi-menu-down</v-icon></span>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters style='margin-top:0px'>
                    <v-col cols='3'>
                        <div class='text-center'>
                            <span class='cTimeframe'>compared with:</span>
                        </div>
                    </v-col>
                    <v-col cols='8'>
                        <div class='text-center'>
                            <span class='cFirst-date'>{{dates.cStartDate}}</span>
                            <span class='date-arrow'><v-icon>mdi-arrow-right</v-icon></span>
                            <span class='cSecond-date'>{{dates.cEndDate}}</span>
                        </div>
                    </v-col>
                    <v-col cols='1'></v-col>
                </v-row>
            </div>
               
                
            </template>
            <v-list>
                <v-list-item
                v-for="(item, index) in items"
                :key="index"
                :class="{ 'active' : activeIndex === index}"
                @click='setCallActive(item,index)'
                >
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <div class='range-picker' :class='{hide:viewRangePicker}'>
            
            <v-date-picker 
                v-model="range" 
                range 
                :max='getRefinedTodayDate()'
                width='366'
            ></v-date-picker>
            <v-date-picker 
                v-model="cRange" 
                range 
                :disabled='range.length===0?true:false' 
                :max='getRefinedTodayDate()'
                width='366'
            >
                <slot>
                    <v-btn @click='toggleRangePicker(true)'>Confirm</v-btn>
                    <v-btn @click='toggleRangePicker(false)'>Close</v-btn>
                </slot>
            </v-date-picker>
        </div>
    </div>
    
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props:['casinoData'],
    data(){
        return{
           
            range: [],
            cRange: [],

            viewRangePicker: true,
            activeIndex: undefined,
            selectedItem: null,
            items: [
                {
                    label: 'Today',
                },
                {
                    label: 'Yesterday',
                },
                {
                    label: 'This Week',
                },
                {
                    label: 'Last Week',
                },
                {
                    label: 'Last 7 days',
                },
                {
                    label: 'Last 28 days',
                },
                {
                    label: 'Last 30 days',
                },
                {
                    label: 'Last 90 days',
                },
                {
                    label: 'Last 12 months',
                },
                {
                    label: 'Custom...',
                }
            ],
        }
    },
    mounted(){
        //this.selectedRange = this.items[0].label;
        this.activeIndex = 0;
        if(this.selectedRange === null) {
            const payload = {
                item: this.items[0],
                casinoData: this.casinoData
            }
            this.getDate(payload).then(response => {
                this.$emit('filterDateAPI');
            }); 
        } else {
            this.$emit('filterDateAPI');
        }
          
    },
    computed: {
        ...mapGetters(['dates']),
        ...mapGetters(['selectedRange']),
    },
    methods: {
        setCallActive(item, index) {
            
            this.selectedItem = item;
            if(index === 9){
                this.viewRangePicker = !this.viewRangePicker;
            } else {
                this.activeIndex = index;
                const payload = {
                    item: item,
                    casinoData: this.casinoData
                }
                this.getDate(payload).then(response => {
                    this.$emit('filterDateAPI')
                });
            }
            
        },

        toggleRangePicker(isTrue) {

            if(isTrue === true){
                if( this.range.length === 0 || this.cRange.length === 0){
                    Vue.toasted.error('Please select a correct range');
                } else {
                    const payload = {
                        item: this.selectedItem,
                        range: this.range,
                        cRange: this.cRange,
                        casinoData : this.casinoData
                    }
                    this.getCustomDate(payload).then(response=>{
                        this.$emit('filterDateAPI')
                    });
                    this.activeIndex = this.items.indexOf(this.selectedItem);
                    this.viewRangePicker = !this.viewRangePicker;

                }
                
                this.range= [];
                this.cRange= [];
            }
            else  {

                
                this.range= [];
                this.cRange= [];
                this.viewRangePicker = !this.viewRangePicker;
            }

            
        },

        getRefinedTodayDate(){                                                                              //casino offset in hours
        const newDate = new Date();
        const msDate = newDate.getTime();                                                                           //tranform date to miliseconds
        const todayDate = msDate - this.$route.params.casino_id*60*60*1000;                                         //subtract casino time offset to determine day in ms
        const todayDateIso = new Date(todayDate);                                                                   //create new Date object from offset date
        const timeZoneOffset = newDate.getTimezoneOffset();                                                         //get time-zone offset
        todayDateIso.setHours(this.$route.params.casino_id-timeZoneOffset/60,0,0);                                                  //set casino offset hour to determined day
        const refinedTodayDate = todayDateIso.toISOString().substring(0, todayDateIso.toISOString().length-8);      //remove seconds and ms from date
        return  refinedTodayDate;
    },
         

        ...mapActions(['calcDates']),

        ...mapActions(['startWeek']),
           
        ...mapActions(['setFilterDate']),
       
        ...mapActions(['getDate']),
        
        ...mapActions(['getCustomDate']),      

    }
}
</script>
