<template>
    <div class="page casino">
        <div class="header">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity">
                            <div class="title">Financial Perspective</div>
                            <div class="subtitle">Remote Play Details</div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="header_button_container">
                            <date-picker v-if='casinoData' :casinoData='casinoData' @filterDateAPI='getData'/>
                        </div>
                    </v-col>
                </v-row>   
			</v-container>
		</div>
        
        <div class="stats_container" v-if="data" data-aos="fade">
                
            <v-slide-group 
            show-arrows='always'
            >
                <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                        <div class='fin_stats_card'>
                            <v-card class="nav_fin_stats_color isBlueReverse">
                                <p class='label'>{{item.label}}</p>
                                <p class='status'>{{item.value}}</p>
                                <v-row no-gutters class='add_info'>
                                    <v-col cols='6'>
                                        <p class='left'>{{item.change}}%</p>
                                    </v-col>
                                    <v-col cols='6'>
                                        <p class='right'>{{item.cValue}}</p>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <div class='nav_fin_stats_add'>
                                <v-row no-gutters class='fin_section' v-for='(option,i) in item.options' :key='i'>
                                    <v-col cols='6'>
                                        <p class="label" v-html='option.label'></p>
                                        <p class='per_change' :class='(option.change>0)?"plus":(option.change<0)?"minus":""' v-html='checkTypeCurrency(option.change)+"%"'></p>
                                    </v-col>
                                    <v-col cols='6'>
                                        <p class='curr_value'>{{option.value}}</p>
                                        <p class='change_value'>{{option.cValue}}</p>
                                    </v-col>
                                    <div class='divider'>
                                        <span class='knob'></span>
                                    </div>
                                </v-row>
                            </div>
                        </div>
                </v-slide-item>
            </v-slide-group>
        </div>
    </div>
</template>
<script>

import DatePicker from '@/components/datepicker/DatePicker.vue'
import { mapGetters, mapActions } from 'vuex';
export default {
    components:{
        'date-picker': DatePicker
    },
    data(){
        return {
            data: null, 
            casinoData:null,              
        }
    },
    computed:{
        cardInfo: function(){
            return [
            {
                label:'DROP',
                value: this.checkTypeCurrency(this.data.results.totalDrop.value),
                change: this.data.results.totalDrop.change,
                cValue: this.checkTypeCurrency(this.data.results.totalDrop.cValue),
                options: [
                    {
                        label: 'drop per <br>active player',
                        value: null,
                        change: 0,
                        cValue: null,
                    },
                    {
                        label: 'drop per<br>new player',
                        value: null,
                        change: 0,
                        cValue: null,
                    },
                    {
                        label: 'drop per<br>session',
                        value: this.checkTypeCurrency(this.data.results.averageDropPerSession.value),
                        change: this.data.results.averageDropPerSession.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageDropPerSession.cValue),
                    },
                    {
                        label: 'drop per<br>player',
                        value: this.checkTypeCurrency(this.data.results.averageDropPerPlayer.value),
                        change:this.data.results.averageDropPerPlayer.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageDropPerPlayer.cValue),
                    },
                    {
                        label: 'drop per<br>gaming hour',
                        value: this.checkTypeCurrency(this.data.results.averageDropPerHour.value),
                        change: this.data.results.averageDropPerHour.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageDropPerHour.cValue),
                    },
                ]
            },
            {
                label:'BET',
                value: this.checkTypeCurrency(this.data.results.totalBet.value),
                change: this.data.results.totalBet.change,
                cValue: this.checkTypeCurrency(this.data.results.totalBet.cValue),
                options: [
                    {
                        label: 'bet per <br>player',
                        value:  this.checkTypeCurrency(this.data.results.averageBetPerPlayer.value),
                        change: this.data.results.averageBetPerPlayer.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageBetPerPlayer.cValue),
                    },
                    {
                        label: 'bet per<br>drop',
                        value: null,
                        change: 0,
                        cValue: null,
                    },
                    {
                        label: 'bet per<br>EGM',
                        value: this.checkTypeCurrency(this.data.results.averageBetPerMachine.value),
                        change:this.data.results.averageBetPerMachine.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageBetPerMachine.cValue),
                    },
                    {
                        label: 'bet per<br>game run',
                        value: this.checkTypeCurrency(this.data.results.averageBetPerGame.value),
                        change: this.data.results.averageBetPerGame.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageBetPerGame.cValue),
                    },
                    {
                        label: 'bet per<br>hour',
                        value: this.checkTypeCurrency(this.data.results.averageBetPerHour.value),
                        change: this.data.results.averageBetPerHour.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageBetPerHour.cValue),
                    },
                ]
            },
            {
                label:'RESULT',
                value: this.checkTypeCurrency(this.data.results.result.value),
                change: this.data.results.result.change,
                cValue: this.checkTypeCurrency(this.data.results.result.cValue),
                options: [
                    {
                        label: 'result per <br>player',
                        value:  this.checkTypeCurrency(this.data.results.averageResultPerPlayer.value),
                        change: this.data.results.averageResultPerPlayer.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageResultPerPlayer.cValue),
                    },
                    {
                        label: 'result per<br>EGM',
                        value:  this.checkTypeCurrency(this.data.results.averageResultPerMachine.value),
                        change: this.data.results.averageResultPerMachine.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageResultPerMachine.cValue),
                    },
                    {
                        label: 'result per<br>manufacturer',
                        value: null,
                        change: 0,
                        cValue: null,
                    },
                    {
                        label: 'result per<br>session',
                        value: this.checkTypeCurrency(this.data.results.averageResultPerSession.value),
                        change: this.data.results.averageResultPerSession.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageResultPerSession.cValue),
                    },
                    {
                        label: 'result per<br>hour',
                        value: this.checkTypeCurrency(this.data.results.averageResultPerHour.value),
                        change: this.data.results.averageResultPerHour.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageResultPerHour.cValue),
                    },
                    {
                        label: 'actual<br> result',
                        value: null,
                        change: 0,
                        cValue: null,
                    },
                    {
                        label: 'theoretical<br> result',
                        value: this.checkTypeCurrency(this.data.results.theoreticalResult.value),
                        change: this.data.results.theoreticalResult.change,
                        cValue: this.checkTypeCurrency(this.data.results.theoreticalResult.cValue),
                    },
                ]
            },
            {
                label:'PLAYERS',
                value: this.checkTypeCurrency(this.data.results.numberOfPlayers.value),
                change: this.data.results.numberOfPlayers.change,
                cValue: this.checkTypeCurrency(this.data.results.numberOfPlayers.cValue),
                options: [
                    {
                        label: 'avg<br>result',
                        value:  this.checkTypeCurrency(this.data.results.averageResultPerPlayer.value),
                        change: this.data.results.averageResultPerPlayer.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageResultPerPlayer.cValue),
                    },
                    {
                        label: 'avg<br>drop',
                        value:  this.checkTypeCurrency(this.data.results.averageDropPerPlayer.value),
                        change: this.data.results.averageDropPerPlayer.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageDropPerPlayer.cValue),
                    },
                    {
                        label: 'avg<br>sessions',
                        value: this.checkTypeCurrency(this.data.results.averageSessionsPerPlayer.value),
                        change: this.data.results.averageSessionsPerPlayer.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageSessionsPerPlayer.cValue),
                    },
                    {
                        label: 'avg<br>EGMs',
                        value: this.checkTypeCurrency(this.data.results.averageMachinesPerPlayer.value),
                        change: this.data.results.averageMachinesPerPlayer.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageMachinesPerPlayer.cValue),
                    },
                    {
                        label: 'avg<br> manufacturers',
                        value: null,
                        change: 0,
                        cValue: null,
                    },
                ]
            },
            {
                label:'EGMs',
                value: this.checkTypeCurrency(this.data.egm.totalAvailable),
                change: 0,
                cValue: null,
                options: [
                    {
                        label: 'avg<br>result',
                        value:  this.checkTypeCurrency(this.data.results.averageResultPerMachine.value),
                        change: this.data.results.averageResultPerMachine.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageResultPerMachine.cValue),
                    },
                    {
                        label: 'avg<br>drop',
                        value:  this.checkTypeCurrency(this.data.results.averageDropPerMachine.value),
                        change: this.data.results.averageDropPerMachine.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageDropPerMachine.cValue),
                    },
                    {
                        label: 'avg<br>players',
                        value: this.checkTypeCurrency(this.data.results.averagePlayersPerMachine.value),
                        change: this.data.results.averagePlayersPerMachine.change,
                        cValue: this.checkTypeCurrency(this.data.results.averagePlayersPerMachine.cValue),
                    },
                    {
                        label: 'avg<br>sessions',
                        value: this.checkTypeCurrency(this.data.results.averageSessionsPerMachine.value),
                        change: this.data.results.averageSessionsPerMachine.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageSessionsPerMachine.cValue),
                    },
                    {
                        label: 'avg<br>games',
                        value: this.checkTypeCurrency(this.data.results.averageGamesPerMachine.value),
                        change: this.data.results.averageGamesPerMachine.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageGamesPerMachine.cValue),
                    },
                ]
            },
            {
                label:'GAME runs',
                value:  this.checkTypeCurrency(this.data.results.gamesPlayed.value),
                change: this.data.results.gamesPlayed.change,
                cValue: this.checkTypeCurrency(this.data.results.gamesPlayed.cValue),
                options: [
                    {
                        label: 'games per<br>session',
                        value:  this.checkTypeCurrency(this.data.results.averageGamesPerSession.value),
                        change: this.data.results.averageGamesPerSession.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageGamesPerSession.cValue),
                    },
                    {
                        label: 'avg<br>bet',
                        value:  this.checkTypeCurrency(this.data.results.averageBetPerGame.value),
                        change: this.data.results.averageBetPerGame.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageBetPerGame.cValue),
                    },
                    {
                        label: 'avg<br>win',
                        value: this.checkTypeCurrency(this.data.results.averagePlayersPerMachine.value),
                        change: this.data.results.averagePlayersPerMachine.change,
                        cValue: this.checkTypeCurrency(this.data.results.averagePlayersPerMachine.cValue),
                    },
                    {
                        label: 'games per<br>EGM',
                        value: this.checkTypeCurrency(this.data.results.averageGamesPerMachine.value),
                        change: this.data.results.averageGamesPerMachine.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageGamesPerMachine.cValue),
                    },
                    {
                        label: 'games per<br>player',
                        value: this.checkTypeCurrency(this.data.results.averageGamesPerPlayer.value),
                        change: this.data.results.averageGamesPerPlayer.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageGamesPerPlayer.cValue),
                    },
                ]
            },
            {
                label:'SESSIONS',
                value:  this.checkTypeCurrency(this.data.results.numberOfSessions.value),
                change: this.data.results.numberOfSessions.change,
                cValue: this.checkTypeCurrency(this.data.results.numberOfSessions.cValue),
                options: [
                    {
                        label: 'avg session<br>duration',
                        value:  this.checkTypeDuration(this.data.results.averageDurationPerSession.value),
                        change: this.data.results.averageDurationPerSession.change,
                        cValue: this.checkTypeDuration(this.data.results.averageDurationPerSession.cValue),
                    },
                    {
                        label: 'avg games per<br>session',
                        value:  this.checkTypeCurrency(this.data.results.averageGamesPerSession.value),
                        change: this.data.results.averageGamesPerSession.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageGamesPerSession.cValue),
                    },
                    {
                        label: 'avg won per<br>session',
                        value: null,
                        change: 0,
                        cValue: null,
                    },
                    {
                        label: 'avg bet per<br>session',
                        value: this.checkTypeCurrency(this.data.results.averageBetPerSession.value),
                        change: this.data.results.averageBetPerSession.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageBetPerSession.cValue),
                    }
                ]
            },
            {
                label:'DURATION',
                value:  this.checkTypeDuration(this.data.results.totalDuration.value),
                change: this.data.results.totalDuration.change,
                cValue: this.checkTypeDuration(this.data.results.totalDuration.cValue),
                options: [
                    {
                        label: 'avg game<br>duration',
                        value:  this.checkTypeDuration(this.data.results.averageDurationPerGame.value),
                        change: this.data.results.averageDurationPerGame.change,
                        cValue: this.checkTypeDuration(this.data.results.averageDurationPerGame.cValue),
                    },
                    {
                        label: 'avg session<br>session',
                        value:  this.checkTypeDuration(this.data.results.averageDurationPerSession.value),
                        change: this.data.results.averageDurationPerSession.change,
                        cValue: this.checkTypeDuration(this.data.results.averageDurationPerSession.cValue),
                    },
                    {
                        label: 'avg player<br>time',
                        value: this.checkTypeCurrency(this.data.results.averageDurationPerPlayer.value),
                        change: this.data.results.averageDurationPerPlayer.change,
                        cValue: this.checkTypeCurrency(this.data.results.averageDurationPerPlayer.cValue),
                    },
                    {
                        label: 'avg bet per<br>session duration',
                        value: null,
                        change: 0,
                        cValue: null,
                    }
                ]
            },
            ]
        },
        ...mapGetters(['selectedRange']),
        ...mapGetters(['filterDate']),
    },
   async mounted(){
        await this.getCasino();
    },
    methods: {
        getCasino(){
            axios.get('system/casinos/'+ this.$route.params.casino_id)
                .then(res=>{
                    this.casinoData = res.data;         
                });
        },

        getData(value){
           
            var self = this;
            
            function egms(){
                return axios.get('operational/casinos/'+self.$route.params.casino_id+'/egms/statuses');
            };

            function results(value){
                return axios.get('operational/casinos/'+self.$route.params.casino_id+'/cResults?'+self.filterDate);
            };

            function topPayouts(value){
                 return axios.get('operational/casinos/'+self.$route.params.casino_id+'/topPayouts?'+self.filterDate);
            };

            function topZones(value){
                 return axios.get('operational/casinos/'+self.$route.params.casino_id+'/topZones?'+self.filterDate);
            };

            function topManufacturers(value){
                 return axios.get('operational/casinos/'+self.$route.params.casino_id+'/topManufacturers?'+self.filterDate);
            };
            
            function moneyBalance(value){
                 return axios.get('operational/casinos/'+self.$route.params.casino_id+'/cMoneyBalance?'+self.filterDate);
            };

            Promise.all([egms(), results(value), topPayouts(value), topZones(value),topManufacturers(value), moneyBalance(value)])
                    .then(res=>{
                        this.data = {};
                        this.data.egm = res[0].data;
                        this.data.results = res[1].data;
                        this.data.topPayouts = res[2].data;
                        this.data.topZones = res[3].data;
                        this.data.topManufacturers = res[4].data;
                        this.data.moneyBalance = res[5].data;
                        //this.data.moneyBalance = res[4].data;
                    });
        },
    }
}
</script>